import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Price from "./Price";
import ProductPriceSeo from "./ProductPriceSeo";
import { useIntl, defineMessages } from "react-intl";

const ProductPrice = ({ prices, prefix, forceFinalPrice }) => {
  const intl = useIntl();

  if (!prices) {
    return null;
  }

  const messages = defineMessages({
    ht: {
      id: "Layout.HT",
      defaultMessage: "Excl. tax",
    },
  });
  const { regularPrice, finalPrice, minPrice } = prices;

  if (
    !forceFinalPrice &&
    minPrice?.priceInclTax?.value?.amount &&
    minPrice?.priceInclTax?.value?.amount > finalPrice.priceInclTax.value.amount
  ) {
    return (
      <div className="product-price">
        <ProductPriceSeo prices={prices} />
        <div className="product-price__price">
          <span className="product-price__prefix">
            <FormattedMessage
              id="components.atoms.Typography.Price.ProductPrice.min"
              defaultMessage="As low as"
            />
          </span>
          <span className="product-price__regular">
            <Price price={minPrice.priceExclTax} />{" "}
            {intl.formatMessage(messages.ht)}
          </span>
        </div>
      </div>
    );
  }
  if (
    regularPrice.priceInclTax.value.amount <=
    finalPrice.priceInclTax.value.amount
  ) {
    return (
      <div className="product-price">
        <ProductPriceSeo prices={prices} />
        <div className="product-price__price">
          {prefix && <span className="product-price__prefix">{prefix}</span>}
          <span className="product-price__regular">
            <Price price={finalPrice.priceExclTax} />{" "}
            {intl.formatMessage(messages.ht)}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="product-price product-price--special">
      <ProductPriceSeo prices={prices} />
      <div className="product-price__price">
        {prefix && <span className="product-price__prefix">{prefix}</span>}
        <span className="product-price__special">
          <Price price={finalPrice.priceExclTax} />{" "}
          {intl.formatMessage(messages.ht)}
        </span>
        <span className="product-price__regular" aria-disabled={true}>
          <Price price={regularPrice.priceExclTax} />{" "}
          {intl.formatMessage(messages.ht)}
        </span>
      </div>
    </div>
  );
};

ProductPrice.propTypes = {
  prices: PropTypes.shape({
    regularPrice: PropTypes.shape({
      priceInclTax: Price.propTypes.price,
    }).isRequired,
    finalPrice: PropTypes.shape({
      priceInclTax: Price.propTypes.price,
    }).isRequired,
  }).isRequired,
  prefix: PropTypes.node,
};

export default ProductPrice;
