import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber, FormattedMessage } from "react-intl";
import invariant from "fbjs/lib/invariant";

export const currenciesWhitelist = ["EUR", "USD", "GBP"];
export const currenciesAllowedList = currenciesWhitelist;

const Price = ({ price: { value } }) => {
  invariant(
    currenciesWhitelist.indexOf(value.currency) > -1,
    `"${value.currency}" is not a whitelisted currency code`
  );

  return (
    <span className={`price`}>
      {value.amount === null ? (
        <FormattedMessage
          id="components.atoms.Typography.Price.notApplicable"
          defaultMessage="N/A"
        />
      ) : (
        <FormattedNumber
          value={value.amount}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={value.currency}
        />
      )}
    </span>
  );
};

Price.propTypes = {
  price: PropTypes.shape({
    value: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.oneOf(currenciesWhitelist).isRequired,
    }),
  }),
};

export default Price;
