import React from "react";
import invariant from "fbjs/lib/invariant";

const keyToComponent = {
  chevrondroite: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
      <path
        d="M17.09,29.63a.49.49,0,0,1-.35-.15.5.5,0,0,1,0-.71l7.61-7.61-7.61-7.61a.5.5,0,0,1,0-.71.48.48,0,0,1,.7,0l8.32,8.32-8.32,8.32A.47.47,0,0,1,17.09,29.63Z"
        transform="translate(-0.18 -0.16)"
      ></path>
      <path
        d="M42.18,42.16H.18V.16h42Zm-41-1h40v-40h-40Z"
        transform="translate(-0.18 -0.16)"
      ></path>
    </svg>
  ),
  blueline: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 417 5">
      <path
        className="cls-1"
        d="M414.5,0a2.5,2.5,0,0,0-2.45,2H5A2.5,2.5,0,1,0,5,3h407.1a2.5,2.5,0,1,0,2.45-3Z"
      ></path>
    </svg>
  ),
  reviewstar: (
    <svg className="star--svg" version="1.1">
      <g viewBox="0 0 102 18">
        <path d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792 5.657 6.243.907-4.517 4.404 1.066 6.218"></path>
      </g>
    </svg>
  ),
  whiteline: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 417 5">
      <path
        className="cls-1"
        d="M414.5,0a2.5,2.5,0,0,0-2.45,2H5A2.5,2.5,0,1,0,5,3h407.1a2.5,2.5,0,1,0,2.45-3Z"
      ></path>
    </svg>
  ),
  facebook: (
    <svg
      className="SVGInline-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="18"
      viewBox="0 0 10 18"
    >
      <g>
        <g>
          <path
            fill="#002C52"
            d="M9.016.004L6.769 0C4.245 0 2.613 1.674 2.613 4.264V6.23H.354a.353.353 0 0 0-.353.354v2.848c0 .195.158.353.353.353h2.26v7.188c0 .195.157.353.353.353h2.947a.353.353 0 0 0 .353-.353V9.785H8.91a.353.353 0 0 0 .353-.353l.001-2.848a.354.354 0 0 0-.353-.354H6.267V4.563c0-.8.191-1.207 1.235-1.207h1.513a.353.353 0 0 0 .354-.354V.357a.353.353 0 0 0-.353-.353z"
          ></path>
        </g>
      </g>
    </svg>
  ),
  linkedin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
    </svg>
  ),
  instagram: (
    <svg
      className="SVGInline-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g>
        <g>
          <g>
            <path
              fill="#002C52"
              d="M16.118 12.287a3.453 3.453 0 0 1-3.452 3.452H5.592a3.453 3.453 0 0 1-3.453-3.452V5.213a3.453 3.453 0 0 1 3.453-3.452h7.074a3.453 3.453 0 0 1 3.452 3.452zM12.666 0H5.592A5.22 5.22 0 0 0 .38 5.213v7.074A5.22 5.22 0 0 0 5.592 17.5h7.074a5.22 5.22 0 0 0 5.213-5.213V5.213A5.22 5.22 0 0 0 12.666 0z"
            ></path>
          </g>
          <g>
            <path
              fill="#002C52"
              d="M9.129 11.516a2.766 2.766 0 1 1 0-5.532 2.766 2.766 0 0 1 0 5.532zm0-7.292A4.531 4.531 0 0 0 4.603 8.75a4.531 4.531 0 0 0 4.526 4.526 4.531 4.531 0 0 0 4.526-4.526A4.531 4.531 0 0 0 9.13 4.224z"
            ></path>
          </g>
          <g>
            <path
              fill="#002C52"
              d="M12.58 4.258a1.085 1.085 0 1 1 2.168 0 1.085 1.085 0 0 1-2.169 0z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  ),
  viadeo: (
    <svg
      className="SVGInline-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="#002C52"
        d="M15.4,1.1c-2.5-0.1-4.2,1.3-5.1,4.3c0.5-0.2,0.9-0.3,1.3-0.3c0.9,0,1.3,0.5,1.2,1.6c-0.1,0.6-0.5,1.5-1.2,2.7 c-0.8,1.2-1.3,1.8-1.7,1.8c-1.1,0-1.8-5.5-2.1-6.9c-0.3-2.1-1.2-3-2.6-2.9C4,1.5,1.8,3.8,0,5.4l0.8,1.1c0.8-0.6,1.3-0.9,1.4-0.9 c1.4,0,2.1,4.8,3.2,8.3c0.7,2,1.6,2.9,2.7,2.9c1.7,0,3.8-1.6,6.2-4.8C16.7,9,17.9,6.5,18,4.8l0,0C18.1,2.4,17.2,1.2,15.4,1.1 L15.4,1.1z"
      />
    </svg>
  ),
  cb: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.012"
      height="19.516"
      viewBox="0 0 15.012 19.516"
    >
      <path
        d="M19.885,10.13H18.759V7.939a4.5,4.5,0,1,0-9.007-.061V10.13H8.626A1.882,1.882,0,0,0,6.75,12.007v9.007A1.882,1.882,0,0,0,8.626,22.89H19.885a1.882,1.882,0,0,0,1.876-1.876V12.007A1.882,1.882,0,0,0,19.885,10.13Zm-4.973,5.855v3.228a.673.673,0,0,1-.624.676.658.658,0,0,1-.69-.657V15.985a1.5,1.5,0,1,1,1.314,0Zm2.533-5.855h-6.38V7.878a3.19,3.19,0,0,1,6.38,0Z"
        transform="translate(-6.75 -3.374)"
      ></path>
    </svg>
  ),
  trash: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.064"
      height="30.849"
      viewBox="0 0 25.064 30.849"
    >
      <path
        d="M6.427,11.568v19.28a1.934,1.934,0,0,0,1.928,1.928H25.707a1.934,1.934,0,0,0,1.928-1.928V11.568H6.427Zm5.784,17.352H10.283v-13.5h1.928Zm3.856,0H14.139v-13.5h1.928Zm3.856,0H17.995v-13.5h1.928Zm3.856,0H21.851v-13.5h1.928ZM28.117,5.784H21.851V3.374a1.45,1.45,0,0,0-1.446-1.446H13.657a1.45,1.45,0,0,0-1.446,1.446v2.41H5.945A1.45,1.45,0,0,0,4.5,7.23V9.64H29.563V7.23a1.45,1.45,0,0,0-1.446-1.446Zm-8.194,0H14.139V3.88h5.784v1.9Z"
        transform="translate(-4.499 -1.928)"
      ></path>
    </svg>
  ),
  user: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 12 12"
      class="icon icon--default icon--default-block"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.7,6.5C8.5,5.9,9.1,5,9.1,3.9c0-1.7-1.4-3.1-3.1-3.1S2.9,2.2,2.9,3.9c0,1.1,0.5,2,1.4,2.6C2.5,7.1,1.2,8.8,1,10.7 c0,0.2,0.2,0.5,0.4,0.5c0.2,0,0.4-0.2,0.4-0.4C2.1,8.7,3.9,7,6,7s3.9,1.6,4.1,3.7c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.5 C10.8,8.8,9.5,7.1,7.7,6.5z M3.8,3.9c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2S7.2,6.2,6,6.2S3.8,5.2,3.8,3.9z" />
    </svg>
  ),
  cart: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 12 12"
      class="icon icon--default"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.4,4.3c0-0.2-0.2-0.3-0.4-0.3H8.3V2.7C8.3,2.4,8.2,2,8,1.7C7.7,1,7,0.6,6.1,0.6C5.3,0.6,4.6,1,4.2,1.7C4,2,4,2.4,4,2.7 v1.2H3.3c-0.2,0-0.4,0.1-0.4,0.3L2,11c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h7.6c0.1,0,0.2,0,0.3-0.1s0.1-0.2,0.1-0.3 L9.4,4.3z M4.7,2.7c0-0.3,0.1-0.5,0.2-0.7c0.2-0.5,0.7-0.7,1.2-0.7s1,0.2,1.2,0.7c0.1,0.2,0.2,0.5,0.2,0.7v1.2H4.7V2.7z M2.8,10.7 l0.8-6H4v0.5h0C3.8,5.3,3.8,5.4,3.8,5.6c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0-0.2-0.1-0.3-0.2-0.4h0V4.7h2.8v0.5h0 C7.4,5.3,7.3,5.4,7.3,5.6c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0-0.2-0.1-0.3-0.2-0.4h0V4.7h0.4l0.8,6H2.8z" />
    </svg>
  ),
};

export const whitelist = Object.keys(keyToComponent).sort();

const Svg = ({ type }) => {
  invariant(whitelist.indexOf(type) > -1, `"${type}" is not a whitelisted svg`);
  if (
    process.env.NODE_ENV === "development" &&
    (type === undefined || type === "")
  ) {
    console.warn("You must set a type.");
  }

  return <>{keyToComponent[type]}</>;
};

export default Svg;
