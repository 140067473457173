import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Icon from "theme/components/atoms/Icon";
import Svg from "theme/components/atoms/Icon/Svg.js";

const messages = defineMessages({
  notificationMessage: {
    id: "components.molecules.IconWithNotification.notificationMessage",
    defaultMessage: "{quantity} new notifications",
  },
});

const IconWithNotification = ({
  notification,
  notificationMessage,
  type,
  ...props
}) => {
  const intl = useIntl();
  if (type && type === "svg") {
    return (
      <span className="icon-with-notification">
        <Svg type={props.icon} />
        {notification && notification > 0 ? (
          <>
            <span
              aria-hidden="true"
              className="icon-with-notification__notification"
            >
              {notification}
            </span>
            <span className="sr-only">
              {notificationMessage ||
                intl.formatMessage(messages.notificationMessage, {
                  quantity: notification,
                })}
            </span>
          </>
        ) : null}
      </span>
    );
  }
  return (
    <span className="icon-with-notification">
      <Icon {...props} />
      {notification && notification > 0 ? (
        <>
          <span
            aria-hidden="true"
            className="icon-with-notification__notification"
          >
            {notification}
          </span>
          <span className="sr-only">
            {notificationMessage ||
              intl.formatMessage(messages.notificationMessage, {
                quantity: notification,
              })}
          </span>
        </>
      ) : null}
    </span>
  );
};

export default IconWithNotification;
