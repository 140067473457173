import React from "react";

const ProductPriceSeo = ({ prices }) => {
  const price = prices.minPrice ?? prices.finalPrice;

  return (
    <>
      <meta
        itemProp="price"
        content={price.priceExclTax.value.amount.toFixed(2)}
      />
      <meta
        itemProp="priceCurrency"
        content={price.priceExclTax.value.currency}
      />
    </>
  );
};

export default ProductPriceSeo;
