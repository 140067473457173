module.exports = {
  analytics: {
    enable: true,
    debug: false,
    defaultSettings: {},
    integrations: [
      {
        name: "google-tag-manager",
        needConsent: false,
        settings: (authorization, otherAuthorizations) => {
          return {
            "Google Tag Manager": {
              containerId: process.env.FRONT_COMMERCE_WEB_GTM,
            },
          };
        },
        script: () =>
          import("@segment/analytics.js-integration-google-tag-manager"),
      },
    ],
  },
};
